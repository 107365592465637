import { Skeleton } from "antd";
import { PaymentContext, PaymentContextProvider, PaymentContextType } from "../../contexts/payment/PaymentContext";
import { useContext, useEffect, useState } from "react";
import { ChannelModel } from "../../models/ChannelModel";
import { DonationSingleResultInterface } from "../../contexts/payment/interfaces/donation-single-result.interface";
import { useHistory, useLocation } from "react-router-dom";
import { PaymentInterfaceParam } from "../channels/Channel";

function PaymentIfortepay() {
  return (
    <PaymentContextProvider>
      <PaymentState />
    </PaymentContextProvider>
  );
}

function PaymentState() {

  const [channel, setChannel] = useState<ChannelModel>();
  const {
    transactionStatusModel,
    loading,
    initPayment,
    setDonationResultV2,
    getDonationDetailForStatus,
    donationDetailForStatus,
    doPaymentIfortepay,
    paymentResultIfortepay
  } = useContext(PaymentContext) as PaymentContextType
  const [donationResult, setDonationResult] = useState<DonationSingleResultInterface>(undefined);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (donationResult) {
      // get donation status every 5 seconds
      const interval = setInterval(() => {
        getDonationDetailForStatus(donationResult?.data?.invoice_code);
      }, 5000);
      // Stop the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, [donationResult])

  useEffect(() => {
    if (location) {
      setChannel((location.state as PaymentInterfaceParam)?.item)
      setDonationResult(
        (location.state as PaymentInterfaceParam)?.donationResult
      );
      setDonationResultV2(
        (location.state as PaymentInterfaceParam)?.donationResult
      );
    }
  }, [location])

  useEffect(() => {
    if (donationResult !== undefined && channel !== undefined) {
      initPayment(donationResult?.data?.id, channel?.id, "");
      doPaymentIfortepay(channel)
    }
  }, [donationResult, channel]);

  useEffect(() => {
    if (paymentResultIfortepay?.data?.data?.payment_url?.length > 0) {
      window.location.href = paymentResultIfortepay?.data?.data?.payment_url
    }
  }, [paymentResultIfortepay])

  return (
    <div
      style={{ margin: 20 }}>
      <Skeleton loading={false}>
        <iframe
          src={paymentResultIfortepay?.data?.data?.payment_url}
          title={paymentResultIfortepay?.data?.data?.transaction_id}
        />
      </Skeleton>
    </div>
  );
}

export default PaymentIfortepay;