import { Card, message, Tabs, Button } from "antd";
import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChannelType } from "../../contexts/channels/ChannelProvider";
import {
  PaymentContext,
  PaymentContextProvider,
  PaymentContextType,
} from "../../contexts/payment/PaymentContext";
import { thousandSeparator } from "../../helpers/thousand_separator";
import { ChannelModel } from "../../models/ChannelModel";
import { PaymentInterfaceParam } from "../channels/Channel";
import "./payment.css";
import { DonationSingleResultInterface } from "../../contexts/payment/interfaces/donation-single-result.interface";
import axios from "axios";
import { CompanySingleResultInterface } from "../../models/CompanyModel";
import { FileAddOutlined } from "@ant-design/icons";
import { UploadFileProvider } from "../../contexts/upload-file/upload-file.provider";
import { PaymentProvider } from "../../contexts/payment/PaymentProvider";
// import TrxModalComponent from "../components/trx-modal.component.tsx.old";
import { WhatsAppOutlined } from "@ant-design/icons";

function Payment() {
  return (
    <PaymentContextProvider>
      <PaymentState />
    </PaymentContextProvider>
  );
}

function PaymentState() {
  const [channel, setChannel] = React.useState<ChannelModel>();
  const [encrypt, setEncrypt] = React.useState<string>("");
  const {
    paymentResult,
    doPayment,
    loading,
    transactionModel,
    cancelPayment,
    initPayment,
    getDonationDetailForStatus,
    donationDetailForStatus,
  } = React.useContext(PaymentContext) as PaymentContextType;
  const [vaNumber, setVaNumber] = React.useState<string | undefined>(
    "123456789"
  );
  const [qrCode, setQrCode] = React.useState<string>();
  const [deeplink, setDeeplink] = React.useState<string>();
  const [bankCode, setBankCode] = React.useState<string>();
  const [donationResult, setDonationResult] =
    React.useState<DonationSingleResultInterface>(undefined);
  const location = useLocation();
  const [selectedFile, setSelectedFile] = useState<string>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // Get the selected file from the input
    const file = e.target.files[0];

    const confirm = window.confirm(
      "Apakah anda yakin ingin mengupload bukti transfer ini?"
    );
    if (confirm === true) {
      setUploading(true);
      // You can perform additional actions with the file if needed
      // For example, you might want to upload the file to a server
      const uploadFileProvider = new UploadFileProvider();
      console.log(file);
      const resp = await uploadFileProvider.uploadSingle(file, (progress) => {
        console.log(progress);
      });
      if (resp?.status === true) {
        // update proof of payment
        const paymentProvider = new PaymentProvider();
        const updateProof = await paymentProvider.uploadProofOfPayment(
          donationResult?.data?.invoice_code,
          resp.data?.id
        );
        if (updateProof?.status === true) {
          window.alert(
            "Terimakasih sudah mengupload bukti transfer, mohon tunggu transaksi anda sedang diproses oleh sistem kami."
          );
          setSelectedFile(resp.data?.id);
        } else {
          window.alert("Upload bukti transfer gagal");
          setSelectedFile(null);
        }
      }
      setUploading(false);
    }
  };

  // React.useEffect(() => {
  //   window.onpopstate = () => {
  //     cancelPayment(transactionModel?.data?.transaction_details?.order_id!);
  //   };
  // }, [transactionModel]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    console.log("zxczxc", donationResult);
    if (donationResult) {
      // get donation status every 5 seconds
      const interval = setInterval(() => {
        getDonationDetailForStatus(donationResult?.data?.invoice_code);
      }, 5000);
      // Stop the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, [donationResult]);

  React.useEffect(() => {
    if (location) {
      setChannel((location.state as PaymentInterfaceParam)?.item);
      setEncrypt((location.state as PaymentInterfaceParam)?.encrypt);
      setDonationResult(
        (location.state as PaymentInterfaceParam)?.donationResult
      );
    }
  }, [location]);

  React.useEffect(() => {
    if (channel) {
      // doPayment(channel, encrypt);
      setVaNumber(channel.account_number);
      if (channel.type !== ChannelType.EWALLET) {
        if (channel.additional_text) {
          if (channel.additional_text.length > 0) {
            setBankCode(channel.additional_text[0]);
          }
        }
      }
    }
  }, [channel]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (donationResult !== undefined && channel !== undefined) {
      console.log(donationResult);
      initPayment(donationResult?.data?.id, channel?.id, "");
    }
  }, [donationResult, channel]);

  React.useEffect(() => {
    if (donationDetailForStatus !== undefined) {
      if (
        donationDetailForStatus?.data?.status === "settlement" ||
        donationDetailForStatus?.data?.status === "capture"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            console.log(response);
            const url = `${response?.data?.platform_donation}/payment/success?order_id=${donationDetailForStatus?.data?.order_id}&result=success`;
            window.location.href = url;
          });
      } else if (
        donationDetailForStatus?.data?.status === "cancel" ||
        donationDetailForStatus?.data?.status === "deny"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/failed?order_id=${donationDetailForStatus?.data?.order_id}&result=failed`;
            window.location.href = url;
          });
      } else if (donationDetailForStatus?.data?.status === "expire") {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/timeout?order_id=${donationDetailForStatus?.data?.order_id}&result=expire`;
            window.location.href = url;
          });
      }
    }
  }, [donationDetailForStatus]);

  return (
    <>
      <div style={{ margin: 20 }}>
        <h1>{channel?.title}</h1>
        <h1>{channel?.subtitle}</h1>
        {channel?.type === ChannelType.MANUAL_BANK_TRANSFER ? (
          <>
            <Card
              style={{
                marginBottom: 10,
              }}
            >
              {vaNumber && (
                <div style={{ float: "left" }}>
                  <span
                    className="account-number-text-block"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Nomor Rekening
                  </span>
                  <span
                    className="account-number"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {vaNumber}
                  </span>
                  <br />
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#1677ff",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      message.info(`Nomor Rekening berhasil di salin.`);
                      navigator.clipboard.writeText(vaNumber);
                    }}
                  >
                    Salin Nomor Rekening
                  </span>
                </div>
              )}
            </Card>
            <Card
              hoverable
              style={{
                marginBottom: 10,
              }}
            >
              <p
                className="amount"
                style={{
                  fontWeight: "bold",
                }}
              >
                Jumlah
              </p>
              <div
                style={{
                  float: "right",
                }}
              >
                <span
                  className="amount-rp"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Rp{" "}
                </span>
                <span
                  className="amount-number"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {thousandSeparator(
                    donationResult?.data?.amount +
                    (donationResult?.data?.unique_code ?? 0)
                  )}
                </span>
                <br />
                <span
                  style={{
                    cursor: "pointer",
                    color: "#1677ff",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    const amountNumberValue = thousandSeparator(
                      donationResult?.data?.amount +
                      (donationResult?.data?.unique_code ?? 0)
                    );
                    message.info(`Jumlah nominal berhasil disalin.`);
                    navigator.clipboard.writeText(amountNumberValue);
                  }}
                >
                  Salin Nominal
                </span>
              </div>
            </Card>
          </>
        ) : (
          <></>
        )}
        <Card hoverable title={"Perhatian"} style={{ marginBottom: 10 }}>
          {/* <span className="transaction-detail-text"></span> */}
          <span
            className="transaction-detail-description"
            style={{
              color: "#F0416C",
              fontSize: "14pt",
            }}
          >
            {process.env.REACT_APP_COMPANY_ID ===
              "ba4bc43d-bb0e-4902-acad-56e9c14c4ef4" ? (
              <b>
                PASTIKAN JUMLAH YANG DITRANSFER SESUAI DENGAN NOMINAL YANG
                TERCANTUM, UNTUK KEMUDAHAN PENDAFTARAN OTOMATIS OLEH SISTEM
              </b>
            ) : (
              <b>
                Silakan transfer sesuai nominal yang tercantum di atas.
                {process.env.REACT_APP_COMPANY_ID !==
                  "6520546e-20e5-4d59-94e5-15c334b28256"
                  ? `Tiga digit
              kode unik akan didonasikan untuk campaign terkait.`
                  : ""}
              </b>
            )}
          </span>
        </Card>
        <Card hoverable title={"Detail Transaksi"} style={{ marginBottom: 10 }}>
          <span className="transaction-detail-text">Nama Produk</span>
          <span className="transaction-detail-description">
            {donationResult?.data?.program_name}
          </span>
        </Card>
        <Card
          hoverable
          title={"Upload Bukti Transfer"}
          style={{
            marginBottom: 10,
          }}
        >
          {uploading ? (
            <span>Loading...</span>
          ) : selectedFile !== null ? (
            <img
              src={`${process.env.REACT_APP_ASSETS_URL}/${selectedFile}`}
              alt="upload_proof_payment"
              style={{
                width: 250,
              }}
            />
          ) : (
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={(e) => {
                fileInputRef.current.click();
              }}
            >
              <FileAddOutlined
                style={{
                  fontSize: 32,
                }}
              />
              <span
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                Upload Bukti Transfer
              </span>
              <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the default file input
                ref={fileInputRef}
                accept="image/*"
              />
            </div>
          )}
          {/* <TrxModalComponent /> */}
        </Card>
        {selectedFile ? (
          <Card
            hoverable
            style={{
              marginBottom: 10,
            }}
          >
            <span>
              Bukti Transfer Berhasil di Upload, Transaksi anda sedang diproses.
            </span>
            <Button
              type="primary"
              onClick={(e) => {
                const url = `${process.env.REACT_APP_PLATFORM_DONATIONS}/payment/waiting`;
                window.location.href = url;
              }}
            >
              Selesai
            </Button>
          </Card>
        ) : null}
        {process.env.REACT_APP_HELP_WORDING !== "" ? (
          <Card hoverable title={"Bantuan"} style={{ marginBottom: 10 }}>
            <span
              className="transaction-detail-description"
              style={{
                color: "#F0416C",
                fontSize: "1rem",
              }}
            >
              <b>{process.env.REACT_APP_HELP_WORDING}</b>
            </span>
            <br />
            <Button
              type="primary"
              shape="round"
              icon={<WhatsAppOutlined />}
              size={"middle"}
              onClick={(e) => {
                const url = `https://wa.me/+${process.env.REACT_APP_HELP_PHONE}`;
                window.location.href = url;
              }}
            >
              Whatsapp
            </Button>
          </Card>
        ) : (
          <></>
        )}
        {channel?.hows?.length > 0 ? (
          <Card
            hoverable
            title={"Cara Pembayaran"}
            style={{ marginBottom: 10 }}
          >
            <Tabs>
              {channel?.hows?.map((item, index) => (
                <Tabs.TabPane tab={item.name} key={index}>
                  {item.steps?.map((item, index) => (
                    <p key={index}>
                      {index + 1}. {item}
                    </p>
                  ))}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Card>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Payment;
